import { Animal } from "../animal/animal.model";
import { Producer } from "../proprietor/producer.model";
import { SaleStatus } from "./sale-status.enum";
import { SaleType } from "./sale-type.model";

export class SaleAnimal {
  idBlockchain?: string;
  proprietarioOrigem?: Producer;
  proprietarioDestino?: Producer;
  documentoProprietarioDestino?: string;
  status?: SaleStatus;
  tipo?: SaleType;
  dataCriacao?: string;
  dataUltimaAtualizacao?: string;
  idAnimais?: Animal[];
  qtdAnimais?: number;

}