
import { dateTimeFilter, underlineToSpace } from "@/filters/custom-filters";
import { SaleAnimal } from "@/models/sale/sale-animal";
import { defineComponent } from "@vue/runtime-core";

const DetailSaleAdmin = defineComponent({
  props: {
    sale: SaleAnimal,
  },
  methods: {
    dateTimeFilter,
    underlineToSpace,
  },
})
export default DetailSaleAdmin;
