
import { defineComponent } from "vue";
import HeaderContent from "@/components/template/HeaderContent.vue";
import { NDataTable, NSelect, NSpace } from "naive-ui";
import { SaleAnimal } from "@/models/sale/sale-animal";
import producerService from "@/services/producer.service";
import saleService from "@/services/sale.service";
import { dateTimeFilter, underlineToSpace } from "@/filters/custom-filters";
import { h, reactive } from "@vue/runtime-core";
import DetailSaleAdmin from "@/components/sale/DetailSaleAdmin.vue";
import { createDebounce } from "@/common/utils";
import GlobalEvent from "@/common/global-event";

const createColumns = () => {
  return [
    {
      type: "expand",
      expandable: () => true,
      renderExpand: (sale: any) => {
        return h(DetailSaleAdmin, { sale });
      },
    },
    {
      title: "Data",
      key: "dataCriacao",
      sorter: "default",
      defaultSortOrder: "descend",
      render(row: any) {
        return dateTimeFilter(row.dataCriacao);
      },
    },
    {
      title: "Destino",
      key: "proprietarioDestino.nome",
      sorter: "default",
    },
    {
      title: "Status",
      key: "status",
      sorter: "default",
      render: (sale: SaleAnimal) => underlineToSpace(sale?.status?.toString()),
    },
    {
      title: "Tipo",
      key: "tipo",
      sorter: "default",
    },
    {
      title: "Qtde. Animais",
      key: "qtdeAnimais",
      sorter: "default",
      render: (sale: SaleAnimal) => sale?.qtdAnimais,
    },
  ];
};

const paginationReactive = reactive({
  page: 1,
  pageSize: 10,
  showSizePicker: false,
  onChange: (page: any) => {
    paginationReactive.page = page;
  },
});

const SearchSale = defineComponent({
  name: 'SearchSale',
  components: { HeaderContent, NDataTable, NSelect, NSpace },

  data() {
    return {
      form: { idBlockchain: "", currentProducerID: "" },
      sales: Array<SaleAnimal>(),
      salesOriginal: null as any,
      listProducers: [],
      columns: createColumns(),
      pagination: paginationReactive,
    };
  },

  methods: {
    idBlockchainDebouncer: createDebounce(),
    getProducers() {
      GlobalEvent.emit("appLoading", true);
      producerService
        .getAll()
        .then((response) => {
          this.listProducers = response.data.map((e: any) => {
            return {
              label: e.data.nome,
              value: e.data.idBlockchain,
            };
          });
        })
        .finally(() => {
          GlobalEvent.emit("appLoading", false);
        });
    },

    clearFilters() {
      this.form.currentProducerID = "";
      this.form.idBlockchain = "";
      this.sales = [];
    },

    searchSales() {
      if (this.form.currentProducerID == "") {
        GlobalEvent.emit("dialog", {
          icon: "bi bi-exclamation-circle",
          messageLabel: "Selecione um produtor para filtrar",
          btnSuccessLabel: "Entendi"
        });
      }

      GlobalEvent.emit("appLoading", true);
      saleService
        .getSaleByIdProducer(this.form.currentProducerID)
        .then((response) => {
          this.sales = response.data;
          this.salesOriginal = response.data;
        })
        .finally(() => {
          GlobalEvent.emit("appLoading", false);
        });
    },
    onIdBlockchainChange() {
      const id: string = this.form.idBlockchain;

      this.sales = this.salesOriginal.filter(
        (portability: any) => portability?.idBlockchain?.indexOf(id) > -1
      );
    },
  },

  beforeMount() {
    this.getProducers();
  },
})
export default SearchSale;
